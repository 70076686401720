import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from 'firebase/messaging';
import { AdminApiFactory, AppApiFactory } from './services/AppFactory';

const appMessageService = AppApiFactory.message;
const adminMessageService = AdminApiFactory.message;

const firebaseConfig = {
  apiKey: "AIzaSyDp-MmA1UsqDmNjDYJ6eAws0lsYUjYKJXs",
  authDomain: "academy-31a7f.firebaseapp.com",
  databaseURL: "https://academy-31a7f-default-rtdb.firebaseio.com",
  projectId: "academy-31a7f",
  storageBucket: "academy-31a7f.appspot.com",
  messagingSenderId: "938117998284",
  appId: "1:938117998284:web:af82d2906f93d075e40469",
  measurementId: "G-MY64MGDSYB"
}

const firebase = initializeApp(firebaseConfig)

const messaging = getMessaging(firebase);
getToken(messaging, { vapidKey: process.env.VUE_APP_MESSAGE_PUB_KEY }).then(token => {
  // save token to server here
  if (localStorage.getItem(process.env.VUE_APP_APP_KEY)) {
    appMessageService.saveToken(token)
  }
  if (localStorage.getItem(process.env.VUE_APP_ADMIN_KEY)) {
    adminMessageService.saveToken(token)
  }
})

export default messaging;

